import { Controller } from "stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = [ "tableView", "detailView", "catalogView", "shoppingCartView", "relatedProductView", "orderViewTitle", "relatedProductTitle", "summaryTotalProducts", "requestVersion", "total" ]

  connect() {
    this.filter()

    // Show shopping cart when broadcasting to OrderWidgetChannel
    consumer.subscriptions.create({channel: "OrderWidgetChannel", order_id: this.orderId}, {
      received: function(data) {
        this.switchToShoppingCart()
      }.bind(this)
    })
  }

  search() {
    this.filterAfterSecond()
  }

  filter() {
    this.loading()
    this.requestVersionTarget.value = this.requestVersion + 1
    this.submitForm()
  }

  filterAfterSecond = debounce(function() {
    this.filter()
  }, 600)

  submitForm = debounce(function() {
    Rails.fire(this.element, 'submit')
  }, 300)

  selectProduct = debounce(function(product_id) {
    fetch(`/order_widget/products/${product_id}`, {credentials: "same-origin"})
      .then(response => response.text())
      .then(function(html) {
        this.detailViewTarget.innerHTML = html
      }.bind(this))
  }, 400) // Debounce ms must be different than submitForm because of a weird race condition

  checkout(event) {
    if (this.orderTotal < 50) {
      event.preventDefault()
      alert("Minimum €50,-")
    }
  }

  loading() {
    this.tableViewTarget.querySelectorAll('.order-widget-root-product, .order-widget-variant').forEach(function(row) {
      row.parentElement.removeChild(row)
    })
    this.tableViewTarget.insertAdjacentHTML('afterbegin', `<div class="order-widget-loading"></div>`)
  }

  relatedProductView(event) {
    event.preventDefault()
    this.element.action = `/order_widget/products/${event.currentTarget.dataset.relatedProductId}/related`
    this.catalogViewTargets.forEach(function(view) {view.style.display = "none"})
    this.shoppingCartViewTargets.forEach(function(view) {view.style.display = "none"})
    this.relatedProductViewTargets.forEach(function(view) {view.style.display = "flex"})
    this.relatedProductTitleTarget.innerText = event.currentTarget.dataset.relatedProductName
    this.filter()
  }

  shoppingCartView(event) {
    event.preventDefault()
    this.switchToShoppingCart()
  }

  switchToShoppingCart() {
    this.element.action = "/order_widget/shopping_cart"
    this.catalogViewTargets.forEach(function(view) {view.style.display = "none"})
    this.shoppingCartViewTargets.forEach(function(view) {view.style.display = "flex"})
    this.relatedProductViewTargets.forEach(function(view) {view.style.display = "none"})
    this.orderViewTitleTarget.innerText = "Winkelwagen"
    this.filter()
  }

  catalogView(event) {
    this.element.action = `/order_widget/products?version=${this.requestVersion}`
    this.catalogViewTargets.forEach(function(view) {view.style.display = "flex"})
    this.shoppingCartViewTargets.forEach(function(view) {view.style.display = "none"})
    this.relatedProductViewTargets.forEach(function(view) {view.style.display = "none"})
    this.filter()
  }

  orderView(event) {
    event.preventDefault()
    let orderId = event.currentTarget.dataset.orderId
    let orderNumber = event.currentTarget.dataset.orderNumber
    this.element.action = `/order_widget/orders/${orderId}`
    this.shoppingCartViewTargets.forEach(function(view) {view.style.display = "flex"})
    this.catalogViewTargets.forEach(function(view) {view.style.display = "none"})
    this.relatedProductViewTargets.forEach(function(view) {view.style.display = "none"})
    this.orderViewTitleTarget.innerText = `${orderNumber}`
    this.filter()
  }

  selectRow(event) {
    this.selectDomRow(event.currentTarget)
  }

  selectRowInput(event) {
    let row = event.currentTarget.closest('.order-widget-root-product, .order-widget-variant')
    this.selectDomRow(row)
  }

  selectRowKeyboard(event) {
    if (event.key == "ArrowUp" || event.key == "ArrowDown") {
      event.preventDefault()
      let sibling = event.key == "ArrowUp" ? this.selectedRow.previousElementSibling : this.selectedRow.nextElementSibling
      if (sibling) { this.selectDomRow(sibling) }
    }
  }

  selectDomRow(row) {
    this.element.querySelectorAll('.order-widget-root-product, .order-widget-variant').forEach(function(row) {
      row.classList.remove('active')
    })
    row.classList.add('active')
    let field = row.querySelector('.order-widget-quantity-control input[type="text"]')
    if(field) { 
      field.focus() && field.select()
    } else {
      let focusedInput = document.querySelector('input:focus')
      if (focusedInput) focusedInput.blur()
    }

    this.selectProduct(row.dataset.productId)
  }

  get orderId() {
    return this.element.dataset.orderId
  }

  get selectedRow() {
    return this.element.querySelector('.order-widget-root-product.active, .order-widget-variant.active')
  }

  get requestVersion() {
    return parseInt(this.requestVersionTarget.value)
  }

  get orderTotal() {
    return parseInt(this.totalTarget.dataset.orderTotal)
  }

}