import { Controller } from "stimulus"
import consumer from "../../channels/consumer"

export default class extends Controller {
  static targets = ["messages", "importCount", "doneButton", "importResult", "form", "imported", "fileErrorButton", "fileForm"]

  connect() {
    consumer.subscriptions.create({channel: "OrderChannel", order_id: this.orderId}, {
      received: function(data) {
        if (data.import_count) this.importCountTarget.innerText = data.import_count

        if (data.message) {
          if (data.error) {
            this.messagesTarget.insertAdjacentHTML("beforeend", `
              <div class="modal-list-item modal-list-item-danger">${data.message}</div>
            `)  
          } else {
            this.messagesTarget.insertAdjacentHTML("beforeend", `
              <div class="modal-list-item modal-list-item-warning">${data.message}</div>
            `)
          }
        }

        if (data.done) this.doneButtonTarget.style.display = "block"

        if (data.file_error) {
          this.importedTarget.style.display = "none"
          this.fileErrorButtonTarget.style.display = "block"
        }

      }.bind(this)
    })
  }

  submitForm() {
    Rails.fire(this.fileFormTarget, 'submit')
    this.formTarget.style.display = "none"
    this.importResultTarget.style.display = "block"
  }

  get orderId() {
    return this.element.dataset.orderId
  }

}