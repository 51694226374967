import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "stockLevel" ]

  setQuantity = debounce(function(product_id, quantity) {
    fetch(`/add_product_to_cart.json`, {
      method: 'post',
      headers: {
        'X-CSRF-Token': this.token,
        'Content-Type': 'application/json'
      },
      credentials: "same-origin",
      body: JSON.stringify({
        product_id: product_id, 
        quantity: quantity
      })
    }).then(response => response.json())
      .then(function(json) {
        if (json.error) {
          this.stockLevelTarget.innerText = json.stock_level
          this.element.classList.add('error')
          this.element.classList.remove('valid')
        } else {
          this.element.classList.remove('error')
          this.element.classList.add('valid')

          // Change shopping cart
          document.querySelector('.order-widget-summary-total').innerHTML = json.summary_html

          // Change shopping cart in header
          document.getElementById('shopping_cart').dataset.contents = json.order_json
          document.getElementById('shopping_cart').dataset.badge = json.quantity_count
        }
      }.bind(this))
  }, 300)

  add(event) {
    let newValue = this.currentValue + 1
    this.inputTarget.value = newValue
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  subtract(event) {
    let newValue = this.currentValue - 1
    if (newValue >= 0) {
      this.inputTarget.value = newValue
      this.inputTarget.dispatchEvent(new Event('change'))
    }
  }

  setMaxStockLevel(event) {
    this.inputTarget.value = parseInt(this.stockLevelTarget.innerText)
    this.inputTarget.dispatchEvent(new Event('change'))
  }

  addToCart(event) {
    let quantity = parseInt(event.currentTarget.value)
    this.element.classList.remove(['error', 'valid'])
    this.setQuantity(this.element.dataset.productId, quantity)
  }

  get currentValue() {
    return parseInt(this.inputTarget.value)
  }

  get token() {
    return document.querySelector('meta[name="csrf-token"]').content
  }

}