import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "input", "label" ]

  choose(event) {
    this.inputTarget.value = event.currentTarget.dataset.value
    this.inputTarget.dispatchEvent(new Event('change'))
    this.labelTarget.innerText = event.currentTarget.dataset.label
    this.element.classList.add('filtered')
    event.preventDefault()
  }

  reset(event) {
    this.inputTarget.value = null
    this.inputTarget.dispatchEvent(new Event('change'))
    this.labelTarget.innerText = null
    this.element.classList.remove('filtered')
    event.preventDefault()
  }

}