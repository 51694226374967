import "@stimulus/polyfills"
import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import { TweenLite, TimelineLite, CSSPlugin } from "gsap/TweenMax"

require("@rails/activestorage").start()

// Vendor
require('cleave.js/dist/cleave.js')

const application = Application.start()
const shared = require.context("./shared", true, /\.js$/)
const smokesmarter = require.context("./smokesmarter", true, /\.js$/)
const diamondflavours = require.context("./diamondflavours", true, /\.js$/)
application.load(definitionsFromContext(shared))
application.load(definitionsFromContext(smokesmarter))
application.load(definitionsFromContext(diamondflavours))